import React from "react";
import "./header.scss";

export default class Header extends React.Component<any, any> {
  render() {
    return <div className={"header"}>
      <h1>Переговорка 1.0</h1>
      <p style={{cursor: "pointer"}} onClick={() => {
        localStorage.removeItem("csrf");
        localStorage.removeItem("jwt");
        document.location.reload();
      }
      }>Выйти</p>
    </div>;
  }
}