export default class User {
  email: string;
  jwtToken: string | null = null;
  limitSeconds: number = 7200;

  constructor(_props: any) {
    // alert(JSON.stringify(_props));
    this.email = _props.email;
    this.jwtToken = _props.jwtToken;
    if (_props.limit_seconds) {
      this.limitSeconds = _props.limit_seconds;
    } else {
      this.limitSeconds = 7200;
    }
    // console.error(JSON.stringify(_props));
    // alert(JSON.stringify(_props));
    // alert(this.limitSeconds);
  }

}