import React from "react";
import MeetingRoom from "../../../models/MeetingRoom";
import "./meeting_room_thumb_view.scss";
import {ApiEngine} from "api-engine";

interface MeetingRoomThumbViewProps {
  active: boolean;
  meetingRoom: MeetingRoom;
  onClick: (_meetingRoom: MeetingRoom) => void;
  api: ApiEngine;
}

export default class MeetingRoomThumbView extends React.Component<MeetingRoomThumbViewProps, any> {
  render() {
    let me = this;

    return <div className={`meeting-room-thumb ${me.props.active ? 'active-thumb' : ''}`}
                onClick={() => {me.props.onClick(me.props.meetingRoom)}}
    >
      <img
        src={`${me.props.api.serverUrl}${me.props.meetingRoom.imgSrc}`}/>
      {/*<p>{me.props.meetingRoom.imgSrc}</p>*/}
      <div className={"toner"}></div>
      <h3>{me.props.meetingRoom.title}</h3>
    </div>
  }
}