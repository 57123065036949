import React from "react";
import "./reservation.scss";
import {ApiEngine} from "api-engine";

interface ReservationProps {
  reservation: any;
  api: ApiEngine;
}

interface ReservationState {
  // reservation: any;
}

function pad(_num: number, size: number) {
  let num = _num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export default class ReservationView extends React.Component<ReservationProps, ReservationState> {
  render() {
    let me = this;
    let offset = new Date().getTimezoneOffset() * 60 * 1000 ;
    // alert(offset);
    let _dateStart = new Date(me.props.reservation.seconds_start * 1000 );
    let _dateEnd = new Date(me.props.reservation.seconds_start * 1000 + 1000 * me.props.reservation.duration );
    let dateStart = new Date(_dateStart.getTime() + 0 * 1000 * 60 * _dateStart.getTimezoneOffset());
    let dateEnd = new Date(_dateEnd.getTime() + 0 * 1000 * 60 * _dateEnd.getTimezoneOffset());
    return <div className={"reservation"}>
      <img
        src={`${me.props.api.serverUrl}${me.props.reservation.meeting_room.image_url}`}/>
      <div className={"content"}>
        <p style={{display: "none"}}>{JSON.stringify(me.props.reservation)}</p>
        <div className={"column"}>
          <p>{me.props.reservation.meeting_room.title}</p>
          <br/>
          {/*<p><i className={"fal fa-clock"}/>{me.props.reservation.duration / 60} мин</p>*/}
          <p className={"time"}>{`${pad(dateStart.getUTCHours(), 2)}:${pad(dateStart.getUTCMinutes(), 2)}`}-{`${pad(dateEnd.getUTCHours(), 2)}:${pad(dateEnd.getUTCMinutes(), 2)}`}</p>
        </div>
        <p className={"id"}>{me.props.reservation.id}</p>
      </div>
    </div>
  }
}