import React from 'react';
import Header from "./components/header/Header";
import "../src/style/color-system.scss";
import "./style/base-design-system.scss"
import LoginZone from "./components/login_zone/LoginZone";
import MeetingRoomsIndex from "./components/meetin_rooms/meeting_rooms_index/MeetingRoomsIndex";
import User from "./models/User";
import MeetingRoom from "./models/MeetingRoom";
import MeetingRoomShow from "./components/meetin_rooms/meeting_room_show/MeetingRoomShow";
import {ApiEngine} from "api-engine";
import ReservationView from "./components/reservations/ReservationView";
import DemoMode from "./components/demo_mode/DemoMode";

interface AppState {
  user: User | null;
  chosenMeetingRoom: MeetingRoom | null;
  myReservations: any[] | null;
  addingSchedule: boolean;
  total_seconds: number;
  limitSeconds: number;
  demoMode: boolean;
}

interface AppProps {
  api: ApiEngine;
}

export default class App extends React.Component<AppProps, AppState> {
  mounted:boolean = false;

  constructor(_props: any) {
    let paramsString = document.location.search;
    const searchParams = new URLSearchParams(paramsString);
    super(_props);
    this.state = {
      user: null,
      chosenMeetingRoom: null,
      myReservations: null,
      addingSchedule: false,
      total_seconds: 0,
      limitSeconds: 7200,
      demoMode: searchParams.has("demo")
    }

    this.setUser = this.setUser.bind(this);
    this.setMeetingRoom = this.setMeetingRoom.bind(this);
    this.updateReservations = this.updateReservations.bind(this);
  }


  componentDidMount() {
    if (this.mounted) return;
    if (this.state.demoMode) return;
    this.mounted = true;
    this.updateReservations();
  }


  updateReservations(_oneTimer=false) {
    let me = this;

    if (me.state.user) {
      let url = `/api/users/my_reservations`;
      me.props.api.asyncFetchWithoutQueing(url, {}).then((_res: any) => {
        me.setState({
          myReservations: _res,
          limitSeconds: me.state.user ? me.state.user.limitSeconds : 7200,
          total_seconds: _res.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.duration,
            0),
          addingSchedule: me.state.addingSchedule || _res.length === 0
        }, () => {
          if (!_oneTimer) setTimeout(me.updateReservations, 10000);
        })
      });
    } else {
      if (!_oneTimer) setTimeout(me.updateReservations, 10000);
    }
  }

  setUser(_user: User) {
    let me = this;
    this.setState({user: _user, limitSeconds: _user.limitSeconds}, () => {
      me.updateReservations(true);
    });
  }

  setMeetingRoom(_meetingRoom: MeetingRoom) {
    this.setState({chosenMeetingRoom: _meetingRoom});
  }

  render() {
    let me = this;

    if (me.state.demoMode) {
      return <DemoMode api={me.props.api} />
    }
    // alert(me.props.api)
    return (
      <div>
        <Header />
        <div className={"main-content"} key={`${me.state.total_seconds} <= ${me.state.limitSeconds}`}>
          {
            me.state.user ?
            <>
              {me.state.myReservations &&
                me.state.myReservations.length > 0 &&
                  <>
                    <h2>Мои резервы переговорок</h2>
                    <h3>Доступно: {me.state.limitSeconds / 60 - me.state.total_seconds / 60} мин</h3>
                    { me.state.myReservations.map((_reserve, _reserveIndex) => {
                      return <ReservationView reservation={_reserve}
                      api={me.props.api}
                      key={`reserve-${_reserveIndex}`}/>
                    }) }
                    <br/>
                    { !me.state.addingSchedule &&
                        (me.state.total_seconds < me.state.limitSeconds ?
                          <button style={{
                            width: "100%"
                          }}
                                  onClick={() => {
                                    me.setState({
                                      addingSchedule: true
                                    });
                                  }}
                          >Добавить бронь</button>
                          :
                          <p>Вы исчерпали свой лимит бронирований на сегодня</p>)
                      }
                  </>
              }
              { me.state.addingSchedule &&
                  <>
                    <h2>Выберите переговорку</h2>
                    <MeetingRoomsIndex
                        api={me.props.api}
                        user={me.state.user}
                        setMeetingRoom={me.setMeetingRoom}
                    />
                  </>
              }
            </>
              :
            <p>Вы еще не вошли</p>
          }

          {
            me.props.api && me.state.chosenMeetingRoom ?
              <MeetingRoomShow
                api={me.props.api}
                key={JSON.stringify(me.state.chosenMeetingRoom)}
                meetingRoom={me.state.chosenMeetingRoom}
                maxPeriodSize={me.state.limitSeconds - me.state.total_seconds}
                onScheldule={() => {
                  me.setState({
                    chosenMeetingRoom: null,
                    addingSchedule: false
                  }, () => {
                    me.updateReservations(true);
                  })
                }}
              />
              :
              (me.state.addingSchedule ? <p>Вы еще не выбрали переговорку</p> : <></>)
          }
        </div>
        { !me.state.user && <LoginZone setUserCallback={me.setUser}
                   api={me.props.api}
        /> }

      </div>
    );
  }
}

