import React from "react";
import MeetingRoomThumbView from "../meeting_room_thumb_view/MeetingRoomThumbView";
import User from "../../../models/User";
import MeetingRoom from "../../../models/MeetingRoom";
import "./meeting_room_index.scss";
import {ApiEngine} from "api-engine";

interface MeetingRoomsIndexProps {
  user: User;
  setMeetingRoom: (_m: MeetingRoom) => void;
  api: ApiEngine;
}

interface MeetingRoomsIndexState {
  meetingRooms: MeetingRoom[];
  activeMeetingRoom: MeetingRoom | null;
}

export default class  MeetingRoomsIndex extends React.Component<MeetingRoomsIndexProps, MeetingRoomsIndexState> {

  constructor(_props: MeetingRoomsIndexProps) {
    super(_props);

    this.state = {
      meetingRooms: [],
      activeMeetingRoom: null
    };

    this.getMeetingRoomsData = this.getMeetingRoomsData.bind(this);
    this.chooseMeetingRoom = this.chooseMeetingRoom.bind(this);
  }

  componentDidMount() {
    if (this.props.user) this.getMeetingRoomsData();
  }

  async fakeMeetingRooms():Promise<MeetingRoom[]> {
    return new Promise((_resolve) => {
      let result = [];
      for(let i = 0; i < 10; i += 1) {
        result.push(new MeetingRoom({id: i, title: `Переговорка ${i + 1}`}))
      }
      _resolve(result);
    });
  }

  getMeetingRoomsData() {
    // DOWNLOAD DATA
    let me = this;
    me.props.api.asyncFetch("/api/meeting_rooms", {}).then((_res) => {
      let meetingRooms = _res.map((_x: any) => {return new MeetingRoom(_x)});
      me.setState({
        meetingRooms: meetingRooms
      });
    });
    // me.fakeMeetingRooms().then((_meetingRooms: MeetingRoom[]) => {
    //   me.setState({
    //     meetingRooms: _meetingRooms
    //   });
    // });
  }

  chooseMeetingRoom(_meetingRoom: MeetingRoom) {
    let me = this;
    me.setState({activeMeetingRoom: _meetingRoom}, () => {
      me.props.setMeetingRoom(_meetingRoom);
    })
  }

  render() {
    let me = this;
    return <><div className={"meeting-rooms-index"}>

      {me.state.meetingRooms.map((_meetingRoom: MeetingRoom, _meetingRoomIndex: number) => {
        return <MeetingRoomThumbView
                      api={me.props.api}
                      active={me.state.activeMeetingRoom === _meetingRoom}
                      onClick={me.chooseMeetingRoom}
                      meetingRoom = {_meetingRoom}
                      key={`meeting-room-thumb-${_meetingRoomIndex}`}
                />
      })
      }
    </div>
    </>
  }
}