export default class MeetingRoom {
  id: number;
  title: string;
  comment: string;
  imgSrc: string;

  constructor(_props: any) {
    this.id = _props.id;
    this.title = _props.title;
    this.comment = _props.comment;
    this.imgSrc = _props.image_url;
  }
}