import React from "react";
import "./login-zone.scss";
import User from "../../models/User";
import {ApiEngine} from "api-engine";

interface LoginZoneProps {
  setUserCallback: (_u: User) => void;
  api: ApiEngine;
}

interface LoginZoneState {
  user: User | null;
  email: string | null;
  password: string | null;
  error: string | null;
}

export default class LoginZone extends React.Component<LoginZoneProps, LoginZoneState> {
  mounted: boolean = false;
  constructor(_props: LoginZoneProps) {
    super(_props);

    this.state = {
      user: null,
      email: null,
      password: null,
      error: null
    };

    this.checkLoginData = this.checkLoginData.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return;
    this.mounted = true;
    let me = this;
    try {
      me.props.api.sessionContainer.checkUser().then((_user: any) => {
        me.props.setUserCallback(_user);
      }).catch((_e: any) => {
      });
    } catch (e) {
    }
  }

  checkLoginData() {
    let me = this;

    // post "/api/users/sign_in", params: {user: {email: user.email, password: pass}}
    me.setState({error: null}, () => {
      me.props.api.asyncFetch("/api/users/sign_in", {method: "POST", body: JSON.stringify({user: {email: me.state.email, password: me.state.password}})}).then((_res) => {
        if (_res.error) {
          me.setState({error: _res.error});
          return;
        }
        // let user = new User({email: me.state.email});
        me.props.api.updateToken(_res.token);
        // alert(me.props.api.sessionContainer.jwtContainer);
        // alert(me.props.api.sessionContainer.jwtContainer);

        me.props.api.sessionContainer.checkUser().then((_user: any) => {
            me.props.setUserCallback(new User(_user));
        });
        // me.props.api.asyncFetch("")
      });
    });
    // let _fakeUser = new User({email: "1@ya.ru"});
    // me.setState({user: _fakeUser}, () => {
    //   me.props.setUserCallback(_fakeUser);
    // })
  }

  render() {
    let me = this;

    if (me.state.user) return <></>;

    return <div className={"login-zone"}>
      <h3>Вход</h3>
      <input type={"email"} placeholder={"Email"} onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {me.setState({email: _e.target.value})} }/>
      <input type={"password"} placeholder={"Пароль"} onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {me.setState({password: _e.target.value})} } />
      { me.state.error && <p className={"error"}>{me.state.error}</p> }
      <hr/>
      <button
        style={me.state.password?.length && me.state.email?.length ? {}  : {opacity: 0.3, pointerEvents: "none"} }
        onClick={me.checkLoginData}>Войти</button>
    </div>
  }
}