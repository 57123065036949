import React from "react";
import {ApiEngine} from "api-engine";
import "./meeting_room_column_view.scss";
import dayjs from "dayjs";

interface MeetingRoomColumnViewProps {
  api: ApiEngine;
  meetingRoom: any;
}

interface MeetingRoomViewState {
  periods: any[];
  day: any;
  duration: number;
  loadingPeriods: boolean;
}

function pad(num: number, size: number):string {
  let numAsString = num.toString();
  while (numAsString.length < size) numAsString = "0" + numAsString;
  return numAsString;
}

function minutesToHours(_delta: number) {
  let _dateStart = new Date();
  let dateStart = new Date(_dateStart.getTime() - _dateStart.getTimezoneOffset());
  // alert(dateStart);
  let res = [];
  let currentDateStart = dayjs(dateStart);
  // currentDateStart = currentDateStart.startOf("day");
  // alert(currentDateStart);
  let endOfDay = currentDateStart.endOf("day");

  if (currentDateStart.minute() !== 0) {
    if (currentDateStart.minute() < 10) {
      currentDateStart = currentDateStart.startOf("hour");
    } else {
      if (currentDateStart.minute() < 45) {
        currentDateStart = currentDateStart.startOf("hour").minute(30);
      } else {
        currentDateStart = currentDateStart.endOf("hour");
      }
    }
  }


  while(currentDateStart.add(_delta, "second") <= endOfDay.add(10, "second")) {
    let endOfPeriod = currentDateStart.add(_delta, "second");
    let period = {
      start: `${pad(currentDateStart.hour(), 2)}:${pad(currentDateStart.minute(), 2)}`,
      end: `${pad(endOfPeriod.hour(), 2)}:${pad(endOfPeriod.minute(), 2)}`,
      startInSeconds: (currentDateStart.toDate().getTime() - currentDateStart.startOf("day").toDate().getTime()) / 1000,
      duration: _delta
    }
    res.push(period);
    currentDateStart = currentDateStart.add(30, "minute");
    console.log(currentDateStart);
  }

  return res;
}


export default class MeetingRoomColumnView extends React.Component<MeetingRoomColumnViewProps, MeetingRoomViewState> {

  updateTimeout = null as any;
  constructor(_props: MeetingRoomColumnViewProps) {
    super(_props);

    this.state = {
      periods: [],
      day: null,
      duration: 30 * 60,
      loadingPeriods: false
    };

    this.checkPeriods = this.checkPeriods.bind(this);
    this.checkPeriod = this.checkPeriod.bind(this);
    this.getDataAboutMeetingRoom = this.getDataAboutMeetingRoom.bind(this);
  }

  componentDidMount() {
    this.getDataAboutMeetingRoom();
  }

  async getDataAboutMeetingRoom() {
    let me = this;
    me.setState({loadingPeriods: true}, async () => {
      let data = minutesToHours(me.state.duration);
      try {
        data = await me.checkPeriods(data);
        me.setState({
          periods: data,
          loadingPeriods: false
        }, () => {
          if (me.updateTimeout) clearTimeout(me.updateTimeout);
          me.updateTimeout = setTimeout(me.getDataAboutMeetingRoom, 15000);});
      } catch (e) {
        // alert("Boom");
        if (me.updateTimeout) clearTimeout(me.updateTimeout);
        me.updateTimeout = setTimeout(me.getDataAboutMeetingRoom, 2000);
      }
    });
  }

  async checkPeriods(_periods: any[]) {
    let me = this;
    let res = [] as any[];

    for(let o of _periods) {
      res.push(await me.checkPeriod(o))
    }
    return res;
  }

  async checkPeriod(_period: any):Promise<any> {
    let me = this;
    // alert(me.props.api);
    return new Promise((_resolve) => {
      let time_start_seconds_of_day = _period.startInSeconds;
      let duration_seconds = _period.duration;
      let url = `/api/meeting_rooms/${me.props.meetingRoom.id}/check_if_slot_available?time_start_seconds_of_day=${time_start_seconds_of_day}&duration_seconds=${duration_seconds}`;
      me.props.api.asyncFetchWithRetries(url, {}, 1000, false, 0).then((_res: any) => {
        if (!me.state.day) {
          me.setState({
            day: _res.day
          }, () => {
            _period.available = _res.result;
            _resolve(_period);
          });
          return;
        }
        _period.available = _res.result;
        _resolve(_period);
      }, (_err) => {
        throw _err;
      }).catch((_e) => {throw(_e);});
    });
  }

  render() {
    let me = this;
    return <div className={"meeting-room-column-view"}>
      <h3>{me.props.meetingRoom.title}</h3>
      <img
        src={`${me.props.api.serverUrl}${me.props.meetingRoom.imgSrc}`}/>
      {/*<p>{JSON.stringify(me.props.meetingRoom)}</p>*/}
      <br/>
      <br/>
      { me.state.periods.map((_period: any, _periodIndex: number) => {
        return <div className={`duration-container`}
                    key={`period-${_periodIndex}`}
                    style={_period.available ? {} : {opacity: 0.2} }

        ><span>{_period.start}</span><span>-</span><span>{_period.end}</span><p style={{display: "none"}}>{JSON.stringify(_period)}</p></div>
      })
      }
    </div>;
  }
}