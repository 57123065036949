import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApiEngine} from "api-engine";
import SessionContainer from "api-engine/src/session/SessionContainer";
import User from "./models/User";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
localStorage.setItem("csrf", "no csrf this time");

let serverUrl = "https://schedule.skobeltsyn.com";
let _sessionContainer = new SessionContainer<User>(User, "/api/users/me");

let api = new ApiEngine(serverUrl, 0, _sessionContainer);
api.startQueue();

root.render(
  <React.StrictMode>
    <App api={api} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
