import React from "react";
import {ApiEngine} from "api-engine";
import "./demo.scss";
import MeetingRoom from "../../models/MeetingRoom";
import MeetingRoomColumnView from "./meeting_room_column_view/MeetingRoomColumnView";

interface DemoProps {
  api: ApiEngine;
}

interface DemoState {
  meetingRooms: any[];
}

export default class DemoMode extends React.Component<DemoProps, DemoState> {
  mounted: boolean = false;

  constructor(_props: DemoProps) {
    super(_props);
    this.state = {
      meetingRooms: []
    };
    this.getMeetingRooms = this.getMeetingRooms.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return;
    this.mounted = true;
    this.getMeetingRooms();
  }

  getMeetingRooms() {
    let me = this;
    me.props.api.asyncFetch("/api/meeting_rooms", {}).then((_res) => {
      let meetingRooms = _res.map((_x: any) => {return new MeetingRoom(_x)});
      me.setState({
        meetingRooms: meetingRooms
      });
    });
  }

  render() {
    let me = this;
    return <div className={"demo"}>
        {
          me.state.meetingRooms.map((_mR: any, _mrIndex) => {
            return <MeetingRoomColumnView
              key={`meeting-room-${_mrIndex}`}
              meetingRoom={_mR}
              api={me.props.api}/>
          })
        }
      </div>
  }
}