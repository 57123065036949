import React from "react";
import "./meeting-room-view.scss";
import MeetingRoom from "../../../models/MeetingRoom";
import _possibleDurations from "./possible_session_durations.json";
import dayjs from "dayjs";
import {ApiEngine} from "api-engine";

interface MeetingRoomShowProps {
  meetingRoom: MeetingRoom;
  api: ApiEngine;
  onScheldule: () => void;
  maxPeriodSize: number;
}

interface MeetingRoomShowState {
  day: any;
  chosenDuration: number;
  chosenStartTimeInSeconds: number;
  periods: any[];
  makingSchedule: boolean;
  activeReservations: any[];
  loadingPeriods: boolean;
}

function pad(num: number, size: number):string {
  let numAsString = num.toString();
  while (numAsString.length < size) numAsString = "0" + numAsString;
  return numAsString;
}

function minutesToHours(_delta: number) {
  let _dateStart = new Date();
  let dateStart = new Date(_dateStart.getTime() - _dateStart.getTimezoneOffset());
  // alert(dateStart);
  let res = [];
  let currentDateStart = dayjs(dateStart);
  // currentDateStart = currentDateStart.startOf("day");
  // alert(currentDateStart);
  let endOfDay = currentDateStart.endOf("day");

  if (currentDateStart.minute() !== 0) {
    if (currentDateStart.minute() < 10) {
      currentDateStart = currentDateStart.startOf("hour");
    } else {
      if (currentDateStart.minute() < 45) {
        currentDateStart = currentDateStart.startOf("hour").minute(30);
      } else {
        currentDateStart = currentDateStart.endOf("hour");
      }
    }
  }


  while(currentDateStart.add(_delta, "second") <= endOfDay.add(10, "second")) {
    let endOfPeriod = currentDateStart.add(_delta, "second");
    let period = {
      start: `${pad(currentDateStart.hour(), 2)}:${pad(currentDateStart.minute(), 2)}`,
      end: `${pad(endOfPeriod.hour(), 2)}:${pad(endOfPeriod.minute(), 2)}`,
      startInSeconds: (currentDateStart.toDate().getTime() - currentDateStart.startOf("day").toDate().getTime()) / 1000,
      duration: _delta
    }
    res.push(period);
    currentDateStart = currentDateStart.add(30, "minute");
    console.log(currentDateStart);
  }

  return res;
}


export default class MeetingRoomShow extends React.Component<MeetingRoomShowProps, MeetingRoomShowState> {

  updateTimeout: any = null;

  constructor(_props: MeetingRoomShowProps) {
    super(_props);

    this.state = {
      day: null,
      chosenDuration: 0,
      chosenStartTimeInSeconds: -1,
      periods: [],
      makingSchedule: false,
      activeReservations: [],
      loadingPeriods: false
    };

    this.getDataAboutMeetingRoom = this.getDataAboutMeetingRoom.bind(this);
    this.periodName = this.periodName.bind(this);
    this.checkPeriods = this.checkPeriods.bind(this);
    this.checkPeriod = this.checkPeriod.bind(this);
    this.schedule = this.schedule.bind(this);
  }

  componentDidMount() {
  }

  async getDataAboutMeetingRoom() {
    let me = this;
    me.setState({loadingPeriods: true}, async () => {
      try {
        let data = minutesToHours(me.state.chosenDuration);
        data = await me.checkPeriods(data);
        me.setState({
          periods: data,
          loadingPeriods: false
        }, () => {
          me.updateTimeout = setTimeout(me.getDataAboutMeetingRoom, 15000);});
      }catch (e) {
        alert(e);
      }
    });
  }

  async checkPeriods(_periods: any[]): Promise<any> {
    return new Promise<any>(async (_resolve, _reject) => {
      try {
        let me = this;
        let res = [] as any[];
        for(let o of _periods) {
          try {
            res.push(await me.checkPeriod(o))
          } catch (e) {
            // alert("No periods");
            // alert(e);
          }
        }
        _resolve(res);
      } catch (e) {
        _reject(e);
      }

    });

  }

  async checkPeriod(_period: any):Promise<any> {
    let me = this;
    // alert(me.props.api);
    return new Promise((_resolve) => {
      let time_start_seconds_of_day = _period.startInSeconds;
      let duration_seconds = _period.duration;
      let url = `/api/meeting_rooms/${me.props.meetingRoom.id}/check_if_slot_available?time_start_seconds_of_day=${time_start_seconds_of_day}&duration_seconds=${duration_seconds}`;
      try {
        me.props.api.asyncFetchWithoutQueing(url, {}).then((_res: any) => {
          if (!me.state.day) {
            me.setState({
              day: _res.day
            }, () => {
              _period.available = _res.result;
              _resolve(_period);
            });
            return;
          }
          _period.available = _res.result;
          _resolve(_period);
        }, (_err: any) => {
          alert(_err);
        }).catch((_e: any) => {
          alert(_e);
        })
      } catch (e) {
        alert(e);
      }
    });
  }

  periodName() {
    let me = this;
    let period = me.state.periods.find((_x: any) => {return _x.startInSeconds === me.state.chosenStartTimeInSeconds;});
    return `${period.start}-${period.end}`;
  }

  async schedule() {
    let me = this;
    // alert(me.props.api);
    return new Promise((_resolve) => {
      let data = {reservation: {
        day_id: me.state.day.id,
        meeting_room_id: me.props.meetingRoom.id,
        seconds_start: me.state.chosenStartTimeInSeconds,
        duration: me.state.chosenDuration
      }};

      let url = `/api/meeting_reservations/scheldule`;
      me.props.api.asyncFetchWithoutQueing(url, {method: "post", body: JSON.stringify(data)}).
      then((_res: any) => {
        // alert(JSON.stringify(_res));
        _resolve(_res);
      })
    });
  }

  render() {
    let me = this;
    return <div className={"meeting-room-view"}>
      <h3>Укажите длительность</h3>
      <div className={"choose-duration"}
           style={me.state.makingSchedule ? {pointerEvents: "none", opacity: 0.8} : {}}>
        { _possibleDurations.map((_duration: any, _durationIndex: number) => {
          if (me.props.maxPeriodSize < _duration.duration) return <></>;
          return <div className={`duration-container ${me.state.chosenDuration === _duration.duration ? 'active-duration' : ''}`}
                      onClick={() => {
                        me.setState({
                          chosenDuration: _duration.duration,
                          chosenStartTimeInSeconds: -1,
                          periods: []
                        }, () => {
                          me.getDataAboutMeetingRoom();
                        });
                      }}
            >{_duration.title}</div>
          })
        }
      </div>

      <div style={{display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}>
        <h3>Укажите Время</h3>
        { me.state.loadingPeriods && <p style={{marginLeft: "auto"}}>Получаю данные</p> }
      </div>
      <div className={"choose-time"}
           style={me.state.makingSchedule ? {pointerEvents: "none", opacity: 0.8} : {}}>

        { me.state.periods.map((_period: any, _periodIndex: number) => {
          return <div className={`duration-container ${me.state.chosenStartTimeInSeconds === _period.startInSeconds ? 'active-duration' : ''}`}
                      style={_period.available ? {} : {opacity: 0.2} }
                      onClick={ () => {
                        me.setState({
                          chosenStartTimeInSeconds: _period.startInSeconds
                        });
                      } }
          ><span>{_period.start}</span><span>-</span><span>{_period.end}</span><p style={{display: "none"}}>{JSON.stringify(_period)}</p></div>
        })
        }
      </div>
      { me.state.chosenStartTimeInSeconds >= 0 && <>
        <hr/>
        <h3>Подтвердите бронирование</h3>
        <p style={{marginTop: 0}}>Бронирование переговорки <b>{me.props.meetingRoom.title}</b> на период <b>{me.periodName()}</b></p>
        <button
            onClick={() => {
              me.setState({makingSchedule: true}, () => {
                me.schedule().then((_resSchedule: any) => {
                  if (_resSchedule.error) {
                    alert(_resSchedule.error);
                    return;
                  }
                  me.props.onScheldule();
                  return;
                  // me.getDataAboutMeetingRoom().then((_res: any) => {
                  //   me.setState({makingSchedule: false}, () => {
                  //     me.setState({
                  //       activeReservations: [...me.state.activeReservations, _resSchedule.reservation]
                  //     }, () => {
                  //       // alert(_resSchedule.message);
                  //       me.props.onScheldule();
                  //     });
                  //   });
                  // })
                });
              })
            }}
            style={me.state.makingSchedule ? {fontSize: "20px", marginTop: "10px", opacity: 0.4, pointerEvents: "none"} : {fontSize: "20px", marginTop: "10px"}}>{ me.state.makingSchedule ? "ПОДТВЕРЖДЕНИЕ" : "ПОДТВЕРДИТЬ"}</button>
          <span style={{display: "flex", height: "100px"}}></span>
      </>
      }
      <span style={{display: "flex", height: "100px"}}></span>
    </div>
  }
}